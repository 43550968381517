import { logService } from '@/common/services/logger';
import {
  Core,
  Display,
} from '@nsftx/systems-sdk';
import { errorParser } from '@/common/services/error-parser';
import DmDisplayError from '../errors/DmDisplayError';
import { getAppRunUuidFromUrl } from '../helpers';

const LOG_PREFIX = '[deviceManagementDisplayService]';
let displayInstance: Display | null = null;
let displayReady = false;

const startKeepAlive = () => {
  logService.info(`${LOG_PREFIX} Start DM appAlive pinging`, {
    code: 'T_DEVICE_MANAGEMENT_PING_START',
  });

  const appRunUuid = getAppRunUuidFromUrl();

  if (!appRunUuid) {
    logService.warn(`${LOG_PREFIX} AppRunUuid not set on ping DM set up`, {
      code: 'T_DEVICE_MANAGEMENT_KEEP_ALIVE_APP_RUN_UUID_MISSING',
    });
    return;
  }

  const keepAlive = () => {
    displayInstance!.sendAppHeartbeatInterval(appRunUuid)
      .then(() => {
        logService.debug(`${LOG_PREFIX} Received success message from http appAlive request to DM`, {
          code: 'T_DEVICE_MANAGEMENT_KEEP_ALIVE_SUCCESS',
          details: {
            appRunUuid,
          },
        });
      })
      .catch((err) => {
        logService.error(`${LOG_PREFIX} Failed to send appAlive to DM`, {
          ...errorParser.parseUpstream(err),
          details: {
            appRunUuid,
          },
          code: 'T_DEVICE_MANAGEMENT_KEEP_ALIVE_ERROR',
        });
      });
  };

  keepAlive();
  setInterval(keepAlive, 15000);
};

const initDmDisplay = (core: Core) => new Promise<Display>((resolve, reject) => {
  const rejectTimeout = window.setTimeout(() => {
    reject(new DmDisplayError(
      `${LOG_PREFIX} Display instance did not receive ready event on init.`,
      'T_DEVICE_MANAGEMENT_DISPLAY_NOT_READY',
    ));
  }, 5000);
  displayInstance = new Display(core);
  displayInstance.on('ready', () => {
    window.clearTimeout(rejectTimeout);
    displayReady = true;
    logService.debug(`${LOG_PREFIX} Display module ready.`);
    startKeepAlive();
    resolve(displayInstance as Display);
  });
});

const getDisplayInstance = (): Display => {
  if (!displayInstance) {
    throw new DmDisplayError(
      `${LOG_PREFIX} Display instance is not initialized.`,
      'T_DEVICE_MANAGEMENT_DISPLAY_NOT_INIT',
    );
  }

  if (!displayReady) {
    throw new DmDisplayError(
      `${LOG_PREFIX} Display instance did not receive ready event.`,
      'T_DEVICE_MANAGEMENT_DISPLAY_NOT_READY',
    );
  }

  return displayInstance;
};

const isDmDisplayReady = () => new Promise<void>((resolve, reject) => {
  if (displayReady) {
    resolve();
  } else {
    reject(new DmDisplayError(
      `${LOG_PREFIX} Display instance not yet ready.`,
      'T_DEVICE_MANAGEMENT_DISPLAY_NOT_READY',
    ));
  }
});

const switchDisplay = async (accountId: string) => {
  await isDmDisplayReady();
  const display = getDisplayInstance();
  const res = await display.BringAppToFront(accountId);
  return res;
};

const getApplication = (appRunUuid: string) => {
  const display = getDisplayInstance();
  return display.getApplication(appRunUuid);
};

export {
  initDmDisplay,
  switchDisplay,
  getDisplayInstance,
  getApplication,
};
