import { createApp, h } from 'vue';
import AppBootError from '@/common/components/AppBootError.vue';
import { initMaintenanceModule } from '@/modules/maintenance';
import { serviceModeHandler } from '@/modules/service-mode';
import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';
import { useOddTypeStore } from '@/common/stores/odd-type';
import { acceptorsService } from '@/modules/acceptors';
import { DmCoreError, DmDisplayError } from '@/modules/device-management';
import { narApiService } from '@/modules/nar';
import { googleAnalyticsService } from '@/modules/google-analytics';
import { printService } from '@/modules/print';
import { virtualKeyboardService } from '@/common/services/virtual-keyboard';
import ticketCheckEventsService from '@/modules/ticket-check/services/ticketCheckEventsService';
import { useConfigSettingsStore } from '@/modules/seven/config-settings';
import TERMINAL_MODULES from '../../modules';

/* @ngInject */
function BootstrapController(
  $state,
  $log,
  $translate,
  $injector,
  $q,
  $document,
  Modules,
  Bootstrap,
  AppResolve,
  errorParser,
  LimitsService
) {
  // Constants
  const vm = this;
  const environmentHandlerStore = useEnvironmentHandlerStore();
  const runtime = environmentHandlerStore.getApplicationRuntime();
  const configSettingsStore = useConfigSettingsStore();
  const runtimeService = runtime.service;

  const listenerForDevTools = () => {
    // eslint-disable-next-line angular/document-service
    document.addEventListener('keydown', (e) => {
      // When user presses CTRL + ALT + J key combinations send request to nar to open DEV tools
      if (e.code === 'KeyJ' && e.ctrlKey && e.altKey) {
        narApiService.openDevTools().catch((err) => {
          $log.debug('[7Terminal.Bootstrap] Failed opening dev tools', errorParser.parseUpstream(err));
        });
      }
    });
  };

  listenerForDevTools();

  vm.showInstallPage = false;
  vm.showNumpad = false;

  vm.showErrorScreen = () => {
    const errorScreenContainer = $document[0].getElementById('app-boot-error-screen');
    const appBootError = createApp({
      render: () => h(AppBootError)
    });

    appBootError.mount(errorScreenContainer);
  };

  /**
   * Handles runtime service installation error based on its type.
   *
   * @param {Error} err
   * @param {Object} res
   */
  vm.handleIsInstalledError = (err, res) => {
    if (err instanceof DmCoreError || err instanceof DmDisplayError) {
      $log.error('[7Terminal.Bootstrap] Failed to connect to Systems SDK Core API', {
        ...errorParser.parseUpstream(err),
        code: 'T_BOOTSTRAP_RUNTIME_INSTALLATION_DM_ERROR'
      });
      Bootstrap.loadTheme('default')
        .then(vm.showErrorScreen)
        .catch((error) => {
          $log.error('[7Terminal.Bootstrap] Failed to load app theme', {
            ...errorParser.parseUpstream(error),
            code: 'T_THEME_LOAD_FAILED'
          });
        });
      return;
    }

    $log.warn('[7Terminal.Bootstrap] Device is not installed? Redirecting to install...', {
      ...errorParser.parseUpstream(err),
      code: 'T_DEVICE_NOT_INSTALLED_REDIRECT'
    });
    Bootstrap.setTranslations(res.translations.data);
    $translate.refresh();
    vm.showInstallPage = true;

    $state.go('app.install');
  };

  Bootstrap.getLocalConfig()
    .then(function (response) {
      Bootstrap.setLocalConfig(response.data);
      vm.config = configSettingsStore.data;

      // need install?
      runtimeService.run()
        .then(function () {
          vm.showInstallPage = false;
          // all fine, procceed with data fetching
          Bootstrap.boot(response.data, runtimeService)
            .then(function () {
              Promise.allSettled([
                $translate.refresh(), // refresh so shopLanguageLoader can re-register translations
                Modules.getLoader().inject('terminalApp')
              ]).then(() => {
                printService.init();
                googleAnalyticsService.init();
                AppResolve.boot().then(function () {
                  var TerminalService = $injector.get('TerminalService');
                  var moduleToLoad = '7Terminal.AnonymousApp';
                  var moduleDefinition = TERMINAL_MODULES.getModuleByNgName(moduleToLoad);
                  TerminalService.init();
                  Modules.loadModule(moduleDefinition.id)
                    .then(() => {
                      TerminalService.startEvents();
                      vm.moduleToLoad = moduleToLoad;
                      vm.showNumpad = true;
                      Modules.loadModulesByPosition('afterAppReady').finally(() => {
                        serviceModeHandler.init();
                        initMaintenanceModule();
                        LimitsService.init();
                        ticketCheckEventsService.init();
                        virtualKeyboardService.init();

                        // Only after all modules that potentially can change acceptors state
                        // are loaded, we decide whether acceptors should be started or
                        // stopped at the app boot
                        acceptorsService.detectInitialAcceptorsState();
                        // in this moment we have active games loaded and populated
                        // so we need to re-register config for nps
                        const oddTypeStore = useOddTypeStore();
                        oddTypeStore.updatePeripheralsConfig();
                      });
                    }).catch((err) => {
                      $log.error('[7Terminal.Bootstrap] Failed to load main module', {
                        ...errorParser.parseUpstream(err),
                        code: 'T_BOOTSTRAP_LOAD_MAIN_MODULE_FAILED'
                      });
                      vm.showErrorScreen();
                    });
                });
              });
            }).then(angular.noop).catch(function (err) {
              $log.error('[7Terminal.Bootstrap] Failed to fetch bootstrap data', {
                ...errorParser.parseUpstream(err),
                code: 'T_FETCH_BOOTSTRAP_FAILED'
              });
              Bootstrap.loadTheme('default')
                .then(vm.showErrorScreen)
                .catch((error) => {
                  $log.error('[7Terminal.Bootstrap] Failed to load app theme', {
                    ...errorParser.parseUpstream(error),
                    code: 'T_THEME_LOAD_FAILED'
                  });
                });
            });
        }).catch(function (err) {
          $q.all({
            translations: Bootstrap.fetchTranslations('en'),
            theme: Bootstrap.loadTheme('default')
          }).then(function (res) {
            vm.handleIsInstalledError(err, res);
          });
        });
    }).catch(function (err) {
      $log.error('[7Terminal.Bootstrap] Failed to fetch local config data.', {
        ...errorParser.parseUpstream(err),
        code: 'T_FETCH_LOCAL_CONFIG_FAILED'
      });
      Bootstrap.loadTheme('default')
        .then(vm.showErrorScreen)
        .catch((error) => {
          $log.error('[7Terminal.Bootstrap] Failed to load app theme', {
            ...errorParser.parseUpstream(error),
            code: 'T_THEME_LOAD_FAILED'
          });
        });
    });
}

export default BootstrapController;
