import { Pocket } from '@nsftx/systems-sdk';
import deviceManagementService from '@/modules/device-management/services/deviceManagementService';
import DmCoreError from '@/modules/device-management/errors/DmCoreError';
import { errorParser } from '@/common/services/error-parser';
import BaseError from '@/common/errors/BaseError';

let pocket: Pocket;
let isReady: boolean = false;

const init = (): Promise<Pocket> => new Promise((resolve, reject) => {
  if (isReady) {
    resolve(pocket);
    return;
  }

  deviceManagementService.getCore().then((core) => {
    pocket = new Pocket(core);

    pocket.on('ready', () => {
      isReady = true;
      resolve(pocket);
    });
  }).catch((error) => {
    reject(new DmCoreError(
      errorParser.parseMessage(error),
      'T_DEVICE_MANAGEMENT_CORE_CONNECTION_ERROR',
      {
        cause: error,
      },
    ));
  });
});

const fetchTransactions = async () => {
  if (!isReady) await init();

  return pocket.fetchTransactions();
};

const fetchTransApoens = async () => {
  if (!isReady) await init();

  return pocket.fetchTransApoens();
};

const transfer = async () => {
  if (!isReady) await init();

  return pocket.emptyCashBox();
};

const payinCancel = async (id: string) => {
  if (!isReady) await init();

  return pocket.payinCancel(id);
};

const getPocket = async () => new Promise<Pocket>((resolve, reject) => {
  if (isReady) {
    resolve(pocket);
  } else {
    reject(new BaseError(
      'Pocket not yet initialized',
      'T_DEVICE_MANAGEMENT_POCKET_INIT_ERROR',
    ));
  }
});

export {
  fetchTransactions,
  fetchTransApoens,
  transfer,
  payinCancel,
  getPocket,
};
