import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  Ref,
} from 'vue';
import { getService } from '@/common/services/ngBridge';
import { logService } from '@/common/services/logger';
import { Widget } from '@/common/stores/widgets';
import { interpolate } from '@/common/filters/stringInterpolationFormatter';
import type {
  GravityIframeProductData,
  GravityIframeConfig,
  GravityIframeBindings,
} from './types';
import { integratorService } from '../integrator';

type GatewayIframeProps = {
  widget: Widget,
  data: GravityIframeProductData,
  bindings: GravityIframeBindings,
  onFinally: (config: GravityIframeConfig) => void,
  iframeId: string
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any

export default /*@__PURE__*/_defineComponent({
  __name: 'TGatewayIframe',
  props: {
    widget: {},
    data: {},
    bindings: {},
    onFinally: { type: Function },
    iframeId: {}
  },
  setup(__props: any) {

let GravityGatewayService: any;
const iframeContainer = ref<HTMLDivElement>() as Ref<HTMLDivElement>;
let resizeObserver: ResizeObserver;

const props = __props;

const getIframeId = (): string => props.widget?.source?.config?.frameId || props.iframeId;

const createIframe = (): HTMLIFrameElement => {
  const interpolatedUrl = interpolate(props.widget.source.url || '', props.bindings);
  const url = new URL(interpolatedUrl);

  url.searchParams.set('v', Date.now().toString());
  url.searchParams.set('integrationType', 'gravityGateway');
  url.searchParams.set('application', 'terminal');
  url.searchParams.set('platform', 'seven');

  const iframeEl = document.createElement('iframe');
  iframeEl.src = url.toString();
  iframeEl.classList.add('game-iframe');
  iframeEl.id = getIframeId();
  iframeEl.style.visibility = 'hidden';

  document.body.appendChild(iframeEl);
  return iframeEl;
};

const getIframe = (): HTMLIFrameElement | null => document
  .getElementById(getIframeId()) as HTMLIFrameElement;

onMounted(() => {
  GravityGatewayService = getService('GravityGatewayService');
  const iframeEl = getIframe() || createIframe();
  const useGateway = props.widget.source.config?.useGateway;

  resizeObserver = new ResizeObserver(() => {
    const settings = iframeContainer.value.getBoundingClientRect();

    iframeEl.style.position = 'fixed';
    iframeEl.style.left = `${settings.left}px`;
    iframeEl.style.top = `${settings.top}px`;
    iframeEl.style.height = `${settings.height}px`;
    iframeEl.style.width = `${settings.width}px`;
    iframeEl.style.zIndex = '1';
    iframeEl.style.visibility = 'visible';
    iframeEl.style.zIndex = '1';
  });

  if (useGateway === undefined || useGateway) {
    const data = {
      iframeSrc: props.widget.source.url || '',
      iframeId: getIframeId(),
      iframeConfig: props.widget.source.config,
      context: props.data,
    };

    if (GravityGatewayService.isFrameLoaded(data.iframeId)) {
      GravityGatewayService.wakeUpSlave(iframeEl, data);
      props.onFinally(data);
    } else {
      GravityGatewayService.initSlave(data)
        .catch(() => {
          logService.info(
            '[TGatewayIframe] Failed to load iframe',
            {
              frameId: getIframeId(),
              frameUrl: props.widget.source.url,
              code: 'T_IFRAME_LOAD_FAIL',
            },
          );
        }).finally(() => {
          GravityGatewayService.wakeUpSlave(iframeEl, data);
          props.onFinally(data);
        });
    }
  }

  resizeObserver.observe(iframeContainer.value);
});

onBeforeUnmount(() => {
  if (resizeObserver) {
    resizeObserver.unobserve(iframeContainer.value);
  }
});

onUnmounted(() => {
  const iframeEl = getIframe();
  if (!iframeEl) return;

  const iframeId = getIframeId();
  iframeEl.style.visibility = 'hidden';

  if (props.widget.source.config?.destroy) {
    GravityGatewayService.removeIframeOnDelay(iframeEl, iframeId);
  }

  integratorService.sendEvent('Slave.Snooze', {
    context: {
      productId: iframeId,
    },
  });

  integratorService.sendEvent('Slave.Mute', {
    context: {
      productId: iframeId,
    },
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "iframeContainer",
    ref: iframeContainer,
    class: "max-size"
  }, "   ", 512 /* NEED_PATCH */))
}
}

})