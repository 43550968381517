import * as maintenanceService from './services/maintenanceService';
import MaintenanceModal from './MaintenanceModal.vue';
import MaintenanceScheduleMessage from './interfaces/MaintenanceScheduleMessage';

const initMaintenanceModule = () => {
  maintenanceService.init();
};

export {
  initMaintenanceModule,
  maintenanceService,
  MaintenanceModal,
};
export { useMaintenanceStore } from './maintenanceStore';
export type {
  MaintenanceScheduleMessage,
};
